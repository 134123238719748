import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrl: './not-found.component.scss'
})
export class NotFoundComponent {
    companyLogo: string = "assets/icons/logo.svg"
    companyName: string = "Exafy"
    errorImage: string = "assets/images/notFound.png"
    statusCode: string = "404"
    errorDescription: string = "Go back to homepage."
    homepageButton: string = "Back to Homepage"
    ajmanLogo: string = "assets/icons/ajman.svg"

    constructor(private title: Title) {
        this.title.setTitle('Not Found');
    }

    onBackHomepageClick() {
        window.open('https://admin-panel.exafysolutions.ae/', '_self');
    }
}
